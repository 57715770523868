/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as FileStoreRecordModule from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');
    
    class FileStoreRecord implements FileStoreRecordModule.FileStoreRecord {
        static fileStoreRecordDexieSchema: string = "[primKey+appID], pdfRef, thumbnailRef, appID";
        public primKey: string;
        public mimeType: string;
        public data: ArrayBuffer;
        public filename: string;
        public filesize: number;
        public extension: string;
        public pdfRef: string | null;
        public thumbnailRef: string | null;
        public originalRef: string | null;
        public chunks: Array<FileStoreRecordModule.IChunks> | null;
        public appID: string;
        public CRC: number | null;

        public get dataAsBlob(): Blob {
            return new Blob([this.data], {
                type: this.mimeType
            });
        }

        constructor(options: FileStoreRecordModule.IFileStoreRecordOptions<ArrayBuffer>) {
            this.primKey = options.primKey;
            this.mimeType = options.mimeType;
            this.data = options.data;
            this.filename = options.filename;
            this.filesize = options.filesize;
            this.extension = options.extension;
            this.pdfRef = options.pdfRef ?? null;
            this.thumbnailRef = options.thumbnailRef ?? null;
            this.originalRef = options.originalRef ?? null;
            this.chunks = options.chunks ?? null;
            this.appID = options.appID;
            this.CRC = options.fileCrc;
        }

        static async fromBlob(options: FileStoreRecordModule.IFileStoreRecordOptions<Blob>) {
            const dataAsArrayBuffer = await options.data.arrayBuffer();

            const fileStoreRecordOptions = <FileStoreRecordModule.IFileStoreRecordOptions<ArrayBuffer>>{
                ...options,
                data: dataAsArrayBuffer
            };

            if (options.pdfRef) {
                fileStoreRecordOptions.pdfRef = options.pdfRef;
            }

            return new FileStoreRecord(fileStoreRecordOptions);
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateFileStoreRecord(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.deleteFileStoreRecord(this);
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts')>({ FileStoreRecord });
})();
